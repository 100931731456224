import { Grid } from "@mui/material";
import VirtualBatteryPercentageCard from "../../VirtualBatteryPercentageCard";
import { useTranslation } from "react-i18next";

export const BatteryDistributionCard = ({ batteryDistribution}) => {
  const { t } = useTranslation()
  const renderCards = batteryDistribution["distribution"]?.map((distribution, i) => {
    let lockedMessage = "";
    if (!distribution["isBateriaVirtualActive"]) {
      lockedMessage = t('common:text.virtual_battery_not_actived_because_of_dates');
    } else if (distribution["locked"]) {
      lockedMessage = t('common:text.bateria_virtual_locked_help');
    }

    return (
      <Grid item key={`${distribution["contractName"]}__${i}`}>
        <VirtualBatteryPercentageCard
          key={`${distribution["contractName"]}__${i}`}
          style={{
            flex: 1,
            width: "100%",
          }}
          active={distribution.current}
          locked={!distribution["current"] && (!distribution["isBateriaVirtualActive"] || distribution["locked"])}
          lockedMessage={lockedMessage}
          percentageData={{
            polissa_name: distribution["contractName"],
            percentage: distribution["pesPercentage"],
          }}
        />
      </Grid>
    );
  });


  return (
    <Grid container>
      {renderCards}
    </Grid>
  )
}

export default BatteryDistributionCard;
