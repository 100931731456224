import {
  Dialog,
  DialogContent,
  DialogTitle,
  Alert
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBateriaVirtualPercentages,
  moveVirtualBatteries,
} from "../../../actions/bateriaVirtual";
import { fetchContracts } from "../../../actions/contracts";
import { i18n } from "../../../config";
import useMoveVirtualBatteryContext from "./useMoveVirtualBatteryContext";
import HorizontalLinearStepper from "./MoveVirtualBatteryStepper/MoveVirtualBatteryStepper";


/*
 * Feature component
 *
 * Responsible for rendering a dialog that contain all the components to make a virtual batery movment
 * - Delegate the rendering of distribution preview to `BateriaVirtualMoveRepartimentPreviewer` component.
 * - Delegate the rendering of new destination batery selector to `BateriaVirtualMoveSelector` component.
 * - Will fetch and posts the changes made
 */
const MoveVirtualBatteryDialog = ({ open, bateriaVirtual, onClose, token }) => {
  const dispatch = useDispatch();
  const {
    selectedBatteryId,
    newPercentage,
    setNewPercentage,
    setBateriaVirtualToMove,
    reset
  } = useMoveVirtualBatteryContext();

  const view_contract = useSelector((state) => state.contracts.view_contract);
  const {
    waitingChangeRequest,
    bateriaVirtualPercentages,
    error
  } = useSelector((state) => state.bateriaVirtual);
  const serverBateriaVirtual = bateriaVirtualPercentages?.find(
    (e) => e.bateria_id === bateriaVirtual.id
  );

  useEffect(() => {
    setBateriaVirtualToMove(bateriaVirtual);
    setNewPercentage(bateriaVirtual.pes_percentatge); // INFO: By default the full weight defined
    dispatch(fetchBateriaVirtualPercentages(bateriaVirtual.bateria.id));
  }, [bateriaVirtual]);

  const handleSubmit = () => {
    dispatch(
      moveVirtualBatteries(
        view_contract.name,
        bateriaVirtual.bateria.id,
        selectedBatteryId,
        newPercentage
      )
    )
      .then((a) => {
        reset();
        dispatch(fetchBateriaVirtualPercentages(bateriaVirtual.bateria.id));
        dispatch(fetchContracts(token, true, 0, null));
        onClose();
      })
      .catch((err) => {
        setNewPercentage(serverBateriaVirtual.percentage);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{i18n.t("common:text.virtual_battery_move")}</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error">
            {i18n.t("common:text.message_cant_do_operation")}
          </Alert>
        )}
        {i18n.t("common:text.virtual_battery_move_notice")}
        <HorizontalLinearStepper
          onClose={onClose}
          onSubmit={handleSubmit}
          disabled={waitingChangeRequest || !selectedBatteryId }
        />
      </DialogContent>
    </Dialog>
  );
};

export default MoveVirtualBatteryDialog;
