import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CircularProgress,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BlockIcon from '@mui/icons-material/Block';

/**
 * UI component
 *
 * Responsible for rendering the provided percentage data.
 */
const VirtualBatteryPercentageCard = ({
    percentageData,
    active,
    style,
    locked,
    lockedMessage,
}) => {
    style = style ?? {};
    const { t } = useTranslation();
    const theme = useTheme();

    const listItemRender = (
        <>
            <ListItem
                style={{ width: "50%", ...style }}
                selected={active}
                disabled={!!locked}
            >
                <ListItemAvatar>
                    <Avatar>
                        <CircularProgress
                            style={{
                                backgroundColor:
                                    theme.palette.grey[
                                    theme.palette.type === "light" ? 200 : 700
                                    ],
                            }}
                            variant="determinate"
                            value={parseFloat(percentageData.percentage)}
                        />
                        {locked && (
                            <BlockIcon style={{ fill: "red", position: "absolute" }} />
                        )}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={`${t("common:text.bateria_virtual_contract")}: ${percentageData.polissa_name
                        }`}
                    secondary={`${percentageData.percentage}%`}
                />
            </ListItem>
        </>
    );

    if (percentageData) {
        if (locked) {
            return (
                <Tooltip title={lockedMessage}>
                    <Card elevation={0}>
                        <CardActionArea disableRipple>
                            {listItemRender}
                        </CardActionArea>
                    </Card>
                </Tooltip>
            )
        } else {
            return listItemRender;
        }
    }
};

export default VirtualBatteryPercentageCard;
