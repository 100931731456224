import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Paper, Slider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBateriaVirtualPercentages, setVirtualBatteryPercentage } from "../../../actions/bateriaVirtual";
import { i18n } from "../../../config";
import VirtualBatteryPercentageSlider from "../VirtualBatteryPercentageSlider";
import useVirtualBattery from "../useVirtualBattery";
import { BatteryDistributionPreview } from "../BatteryDistributionPreview";
import { fetchContracts } from "@/actions/contracts";


/**
 * Feature component
 *
 * Responsible for rendering a dialog with a slider to edit percentage values related to the distribution of virtual battery percentages
 *
 * - Delegate the rendering of percentage distribution to the `BatteryDistributionPreview` component.
 *
 */
const ChangePercentageVirtualBatteryDialog = ({open, bateriaVirtual, onClose}) => {
  const dispatch = useDispatch();
  const [ percentatge, setPercentatge ] = useState();
  const { view_contract, items } = useSelector((state) => state.contracts);
  const {
    waitingChangeRequest,
    isFetchingBateriaVirtualPercentages,
    bateriaVirtualPercentages,
    error
  } = useSelector((state) => state.bateriaVirtual);
  const serverBateriaVirtual = bateriaVirtualPercentages?.find((e) => e.bateria_id === bateriaVirtual.id);
  const {getBatteriesDistribution} = useVirtualBattery();
  const batteriesDistribution = getBatteriesDistribution({contracts: items});

  const selectedBatteryDistribution = batteriesDistribution[bateriaVirtual.bateria.id];

  useEffect(() => {
    setPercentatge(bateriaVirtual.pes_percentatge);
    dispatch(fetchBateriaVirtualPercentages(bateriaVirtual.bateria.id));
  }, [bateriaVirtual, open]);


  const handleSubmit = () => {
    dispatch(setVirtualBatteryPercentage(view_contract.name, bateriaVirtual.bateria.id, percentatge))
      .then((a) => {
        dispatch(fetchBateriaVirtualPercentages(bateriaVirtual.bateria.id));
        dispatch(fetchContracts()); // TODO: Aixo us workaround que tard o dhora shaura de canviar, no pot ser que cada vegada hagi de fer el fetch de contracts, per actualitzar els valors d'aquest sol contracte, es absurd, pero per ara ho fem aixi, la tasca sen va massa de mare sino.

      })
      .catch(err => {
        setPercentatge(serverBateriaVirtual.percentage);
      })
  };


  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {i18n.t('common:text.virtual_battery_modify_percentage')}
      </DialogTitle>
      <DialogContent style={{overflow: "hidden"}}>
        {error && <Alert severity="error">{ i18n.t('common:text.message_cant_do_operation') }</Alert>}
        {i18n.t('common:text.virtual_battery_notice')}
        <VirtualBatteryPercentageSlider
          style={{marginTop: "2rem"}}
          value={percentatge}
          aria-labelledby="discrete-slider"
          step={1}
          min={0}
          max={100}
          onChange={(event, newValue) => setPercentatge(newValue)}
          disabled={waitingChangeRequest}
        />
        { (!isFetchingBateriaVirtualPercentages && !bateriaVirtualPercentages) ? null : <>
          <Typography variant="h6">
            {i18n.t("common:text.battery_distribution_preview")}:
          </Typography>
          <BatteryDistributionPreview
            currentBatteryDistribution={selectedBatteryDistribution}
            newPercentage={percentatge}
            selectedBatteryId={bateriaVirtual.bateria.id}
            expectedAction="changePercentage"
          />
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='primary' onClick={onClose} disabled={false}>
          {i18n.t('common:text.online_send_close')}
        </Button>
        <Button
          key={'send'}
          variant={'text'}
          color={'primary'}
          onClick={handleSubmit}
          disabled={waitingChangeRequest || serverBateriaVirtual?.percentage == percentatge}
        >
          { waitingChangeRequest ? <CircularProgress color="secondary" style={{ marginRight: 10 }} size={25} /> : null }
          { i18n.t('common:text.contract_modification') }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePercentageVirtualBatteryDialog;
