import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { 
  Box, Grid, Paper, 
  Typography, Divider, TextField, 
  FormHelperText, Checkbox, FormControlLabel
} from "@mui/material";
import { Button, NumericFormatField } from "@gisce/oficina-virtual-components";
import { useForm, Controller } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from '@hookform/resolvers/zod';
import Settings from "../../settings";
import AccessTariffSelectField from "../form-fields/AccessTariffSelectField";
import ElectricityProductSelect from "../form-fields/ElectricityProductSelect";
import FileUploadField from "../form-fields/FileUploadField";
import RadioGroupField from "../form-fields/RadioGroupField";
import CouponField from "../form-fields/CouponField";
import { useTranslation } from "react-i18next";
import { RootState } from "@/store";
import { Product } from "@/types/models/services/products";
import { default as productsService } from "@/services/products";
import useI18n from "@/hooks/useI18n";

interface IFormAboutContract {
  onGoBack: () => void;
  onSubmit: (values: StepAboutContractFormData) => void;
}

type StepAboutContractFormData = {
  power: number;
  power2: number;
  power3: number;
  power4: number;
  power5: number;
  power6: number;
  accessTariff: number;
  selectedProduct: number;
  selectedProductName: string;
  selfcons: boolean;
  selfconsAttachments: {
    name: string;
    content: string;
  }[];
  promotionalCode: string;
}

const FormAboutContract: FC<IFormAboutContract> = ({
  onGoBack, 
  onSubmit
}) => {

  const { t } = useTranslation();
  const contractationSettings = Settings.contractation.electricity;
  const { currentLang } = useI18n();

  const elecContractration = useSelector((state: RootState) => state.electricityContractation);

  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);

  const schema = z.object({
    power: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).min(1, {message: t('common:text.required_field')}),
    power2: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).min(1, {message: t('common:text.required_field')}),
    power3: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).optional(),
    power4: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).optional(),
    power5: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).optional(),
    power6: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).optional(),
    accessTariff: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).min(1, {message: t('common:text.required_field')}),
    selectedProduct: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).positive({message: t('common:text.required_field')}),
    selectedProductName: z.string(),
    promotionalCode: z.string().optional(),
    selfcons: z.boolean().optional(),

    // TODO: Use the type File together with the new MultiFileUpload or
    // import { MuiFileInput } from 'mui-file-input'
    selfconsAttachments: z.object({
      name: z.string(),
      content: z.string().regex(/^data:.*\/[a-zA-Z]+;base64,/, "Invalid base64 format"),
    })
      .refine((file) => {
        // .refine((file) => {
        //   return file.size >= 15000000;
        // }, 'File size must be less than 15MB')

        return atob(file.content.substring(file.content.indexOf(',') + 1)).length < 15000000;
      }, 'File size must be less than 15MB')
      .optional().array(),
  }).superRefine((data, ctx) => {
    if (data.power > 15 || data.power2 > 15) {
      const powersCheck: (keyof Pick<
        StepAboutContractFormData, 
        "power" | "power2" | "power3" | "power4" | "power5" | "power6"
      >)[] = ["power", "power2", "power3", "power4", "power5", "power6"];

      for (let i = 0; i < powersCheck.length - 1; i++) {
        const current = powersCheck[i];
        const next = powersCheck[i + 1];

        if (data[current] !== undefined && data[next] !== undefined && data[next] < data[current]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t(`common:text.field_must_be_greater_than_previous_field`),
            path: [next],
          });
        }
      }

      powersCheck.map(powerNum => {
        if (!(powerNum in data) || (powerNum in data && data[powerNum] && data[powerNum] <= 0)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('common:text.required_field'),
            path: [powerNum],
          });
        }
      })
    }
    
    if (contractationSettings.selfcons?.enabled && data.selfcons &&
      (contractationSettings.selfcons?.requireDocumentation ?? true)) {
        if (!data["selfconsAttachments"].length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('common:text.required_field'),
            path: ["selfconsAttachments"],
          });
        }
      }

  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<StepAboutContractFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      power: elecContractration.power,
      power2: elecContractration.power2,
      power3: elecContractration.power3,
      power4: elecContractration.power4,
      power5: elecContractration.power5,
      power6: elecContractration.power6,
      accessTariff: elecContractration.accessTariff,
      selectedProduct: elecContractration.selectedProduct,
      selectedProductName: elecContractration.selectedProductName,
      selfcons: elecContractration.selfcons,
      selfconsAttachments: elecContractration.selfconsAttachments,
      promotionalCode: elecContractration.promotionalCode
    }
  });

  const power = watch("power");
  const power2 = watch("power2");
  const power3 = watch("power3", 0);
  const power4 = watch("power4", 0);
  const power5 = watch("power5", 0);
  const power6 = watch("power6", 0);
  const watchedpromotionalCode = watch("promotionalCode");
  const watchedAccessTariff = watch("accessTariff");
  const watchedSelectedProduct = watch("selectedProduct");
  const watchedSelfcons = watch("selfcons");

  console.log("selectedProduct", watchedSelectedProduct)

  //TODO: Mirar si no cal. Si no cal s'ha d'esborrar.
  const maxPower = Math.max(power, power2, power3, power4, power5, power6);

  const onAboutContractSubmit = async (formValues: StepAboutContractFormData) => {
    const values = Object.assign({}, elecContractration, formValues)
    await onSubmit(values)
  };

  const sixPowers = (power > 15 || power2 > 15);

  const allPowersFull = sixPowers ? 
    (!!(power && power2 && power3 && power4 && power5 && power6)) 
    : (!!(power && power2));

  const powerField = (
    name: keyof Pick<
      StepAboutContractFormData, 
      "power" | "power2" | "power3" | "power4" | "power5" | "power6"
    >,
  ) => (<>
    <Controller
      name={name}
      control={control}
      render={({ field }) => <TextField
        sx={{width: '100%'}}
        label={t('common:text.contractation_' + name)}
        InputProps={{
          inputComponent: NumericFormatField,
          inputProps: {
            suffix:" kW",
            allowNegative: false,
            allowedDecimalSeparators: [","],
            decimalScale: 3,
            decimalSeparator: ",",
            thousandSeparator: ".",
          }
        }}
        variant="standard"
        error={!!errors[name]}
        {...field}
      />}
    />
    {errors[name]?.message && 
      <FormHelperText error={true} id="area-field-error-text">
        {errors[name].message}
      </FormHelperText>
    }
  </>);

  const domesticPowerNames: Parameters<typeof powerField>[0][] = [
    "power", "power2"
  ];

  const domesticPowerFields = <>
    <Grid container item sm={12} spacing={2}>
      {domesticPowerNames.map((powerName) => (
        <Grid item xs={12} sm={6} key={powerName}>
          {powerField(powerName)}
        </Grid>
      ))}
    </Grid>
  </>

  const industrialPowerNames: Parameters<typeof powerField>[0][] = [
    "power3", "power4", "power5", "power6"
  ];

  const industrialPowerFields = <>
    <Grid container item md={12} spacing={2}>
      {industrialPowerNames.map((powerName) => (
          <Grid item xs={12} sm={6} key={powerName}>
            {powerField(powerName)}
          </Grid>
        )
      )}
    </Grid>
  </>

  const fetchAvailableProducts = async () => {
    setIsFetchingProducts(true);
    let maxPower;
    if (power <= 15 && power2 <= 15) {
      maxPower = Math.max(power, power2);
    } else {
      const allPowers = [power, power2, power3, power4, power5, power6].filter(p => p !== undefined);
      maxPower = Math.max(...allPowers);
    }
    productsService.fetchAvailableProducts({
      power: maxPower,
      selfcons: watchedSelfcons,
      accessTariff: watchedAccessTariff, 
      cups: elecContractration.cups, 
      promotionalCode: watchedpromotionalCode
    }, {params: {lang: currentLang.value}}
    ).then(res => {
      setAvailableProducts(res);
      const currentSelectedProductId = res.find(prod => prod.id === elecContractration.selectedProduct)?.id;
      if(currentSelectedProductId) {
        setValue("selectedProduct", currentSelectedProductId);
      }
    }).finally(() => {
      setIsFetchingProducts(false);
    })
  };

  useEffect(() => {
    if(power && watchedAccessTariff) {
      fetchAvailableProducts();
    }
  }, [power, watchedAccessTariff, watchedpromotionalCode]);


  useEffect(() => {
    if(!availableProducts.find(prod => prod.id === watchedSelectedProduct)) {
      setValue("selectedProduct", -1);
    }
  }, [availableProducts]);

  useEffect(() => {
    if ( watchedSelectedProduct ) {
      const selectedProductName = availableProducts.find(prod => prod.id === watchedSelectedProduct)?.name;
      if (selectedProductName) {
        setValue("selectedProductName", selectedProductName);
      }
    }
  }, [watchedSelectedProduct]);

  return (
    <div>
      <form onSubmit={handleSubmit(onAboutContractSubmit)}>
        {(contractationSettings.selfcons?.showSellMessage ?? true) &&
          <Typography style={{fontWeight: "bold"}}>
            {t('common:text.contractation_selfcons_message')}
          </Typography>
        }
        <>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <Box sx={{ml: {md: 5}, mr: {md: 5}, mb: 2, mt: 2}}>
                <Grid container spacing={3}>

                  {domesticPowerFields}
                  {sixPowers && industrialPowerFields}

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={"accessTariff"}
                      control={control}
                      render={({ field }) => <AccessTariffSelectField
                        label={t('common:text.contractation_access_tariff')}
                        power={{power, power2}}
                        meta={{
                          touched: !!errors.accessTariff?.message,
                          error: errors.accessTariff?.message,
                        }}
                        input={field}
                      />}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            { (contractationSettings.selfcons?.enabled ?? true) &&
              <Grid item sm={12} md={6}>
                <Paper style={{padding: 15}}>
                  { contractationSettings.selfcons?.radioGroupVariant ?
                    <Controller
                      name={"selfcons"}
                      control={control}
                      render={({ field }) => <RadioGroupField
                        label={
                          <Typography variant="h6" color="primary">
                            {t('common:text.contractation_selfcons')}
                          </Typography>
                        }
                        radioElements={
                          [
                            {label: t("common:text.generic_yes"), val: true, style: {width: "25%"}},
                            {label: t("common:text.generic_no"), val: false, style: {width: "25%"}}
                          ]
                        }
                        meta={{
                          touched: !!errors.selfcons?.message,
                          error: errors.selfcons?.message,
                        }}
                        input={field}
                      />}
                    />
                  :
                    <>
                      <Controller
                        name={"selfcons"}
                        control={control}
                        render={({ field }) => <FormControlLabel
                          control={<Checkbox
                            {...field}
                            checked={field.value}
                            />} 
                          label={
                            <Typography variant="h6" color="primary">
                              {t('common:text.contractation_selfcons')}
                            </Typography>
                          } 
                        />}
                      />
                      {errors.selfcons?.message &&
                        <FormHelperText error={true}>
                          {errors.selfcons.message}
                        </FormHelperText>
                      }
                    </>
                  }
                  {watchedSelfcons && (
                    <Controller
                      name={"selfconsAttachments"}
                      control={control}
                      render={({ field }) => <FileUploadField
                        min={1}
                        max={3}
                        label={t('common:text.contractation_selfcons_document')}
                        hint={t('common:text.contractation_selfcons_helper')}
                        anotherLabel={t('common:text.contractation_selfcons_add')}
                        removeLabel={t('common:text.remove')}
                        meta={{
                          touched: !!errors.selfconsAttachments,
                          error: errors && Array.isArray(errors.selfconsAttachments) && errors.selfconsAttachments.map(err => err?.message),
                        }}
                        input={field}
                      />}
                    />
                  )}
                  {!!(errors.selfconsAttachments) &&
                    <FormHelperText error={true}>
                      {errors.selfconsAttachments.message}
                    </FormHelperText>
                  }
                </Paper>
              </Grid>
            }
          </Grid>
        </>
        {watchedAccessTariff && allPowersFull &&(
          <>
            {Settings?.features?.promotionalCode &&
              <>
                <Box m={1} style={{marginLeft: 0}} color="textSecondary">
                  <Typography variant="h6" color="primary">
                    {t('common:text.contractation_promotional_code')}
                  </Typography>
                  <Typography variant="body2" style={{marginBottom: 15}}>
                    {t('common:text.contractation_promotional_detail')}
                  </Typography>
                </Box>

                <Controller
                  name="promotionalCode"
                  control={control}
                  key="controllerpromotionalCode"
                  render={({ field }) => <CouponField {...field} outterIsSubmitting={isSubmitting} />}
                />

                {watchedpromotionalCode && <p>
                    {t("common:text.contractation_promotional_applied", 
                      {promocode: watchedpromotionalCode})}
                  </p>
                }
              </>
            }

            <Box m={1} style={{marginLeft: 0}} color="textSecondary">
              <Typography variant="h6" color="primary">
                {t('common:text.contractation_new_pricelist')}
              </Typography>
              <Typography variant="body2" style={{marginBottom: 15}}>
                {t('common:text.contractation_current_pressure_warning')}
              </Typography>
            </Box>
            <Box sx={{ml: {md: 5}, mr: {md: 5}}}>
              <Controller
                name={"selectedProduct"}
                control={control}
                render={({ field }) => <ElectricityProductSelect
                  availableProducts={availableProducts}
                  loading={isFetchingProducts}
                  input={field}
                />}
              />
              {!!(errors.selectedProduct) &&
                <FormHelperText error={true}>
                  {errors.selectedProduct.message}
                </FormHelperText>
              }
            </Box>
          </>
        )}

        <Divider sx={{mt: 3, mb: 3}}/>
        <Button
          variant={'text'}
          onClick={onGoBack}
          style={{ marginRight: 12 }}
          disabled={isSubmitting}
        >
          {t('common:text.contractation_previous')}
        </Button>
        <Button
          type="submit"
          color={'primary'}
          variant={'contained'}
          loading={isSubmitting}
          disabled={!!errors.selectedProduct}
        >
          {t('common:text.contractation_next')}
        </Button>
      </form>
    </div>
  );
};

export default FormAboutContract;
