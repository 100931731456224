import {
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { Create } from '@mui/icons-material';
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { i18n } from "../../../config";
import ChangePercentageVirtualBatteryDialog from './ChangePercentageVirtualBatteryDialog';
import useVirtualBattery from '../useVirtualBattery';


const ChangePercentageVirtualBatteryDialogOpenButton = ({bateriaVirtual}) => {
  const { isBateriaVirtualActive } = useVirtualBattery();
  const sending = useSelector((state) => state.bateriaVirtual.waitingChangeRequest);
  const {readonly: tokenReadOnly} = useSelector(state => state.auth.tokenVals);
  const [ dialogOpen, setDialogOpen ] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };


  const renderButton = (
    <div>
      <span>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleDialogOpen}
          disabled={tokenReadOnly || !isBateriaVirtualActive(bateriaVirtual)}
        >
          {(sending) ? <CircularProgress color='secondary' style={{ marginRight: 10 }} size={25} /> : <Create style={{marginRight: 10}}/>}
          { i18n.t('common:text.virtual_battery_modify_percentage') }
        </Button>
      </span>
      <ChangePercentageVirtualBatteryDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        bateriaVirtual={bateriaVirtual}
      />
    </div>
  );

  if (!isBateriaVirtualActive(bateriaVirtual)) {
    return (
      <Tooltip title={i18n.t('common:text.virtual_battery_not_actived_because_of_dates')}>
        {renderButton}
      </Tooltip>
    );
  }
  return renderButton;

}


export default ChangePercentageVirtualBatteryDialogOpenButton;
