import { useTranslation } from "react-i18next";
import { ErrorOutline } from "@mui/icons-material";
import _ from "lodash";
import { useSelector } from "react-redux";
import { dayjs } from "../../../config";
import Settings from "../../../settings";
import PaymentDialogButton from "../PaymentDialog";
import StripePaymentDialog from "../StripePaymentDialog";
import { DownloadButton } from "../DownloadButton";
import useI18n from "@/hooks/useI18n";
import { Tooltip, useMediaQuery, Typography, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';


// Payment provider (default is stripe)
const paymentProvider = _.get(Settings, "paymentProvider", "stripe");

// Stripe Key
const stripeKey = _.get(Settings, "stripe.key", null);

const useInvoicesTable = ({type}) => {
  const loaded = useSelector((state) => state.invoices[type].loaded);
  const invoices = useSelector((state) => state.invoices[type].items);

  const { t } = useTranslation();
  const { currentLang } = useI18n();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const columnsElectricity = [
    {
      title: t('common:text.invoices_invoice_number'),
      key: "num",
      computeValue: (invoice) => invoice.number
    }, {
      title: t('common:text.invoices_date'),
      key: "date",
      computeValue: (invoice) => dayjs(invoice.date).format("L")
    }, {
      title: t('common:text.invoices_period'),
      key: "period",
      computeValue: (invoice) => `${dayjs(invoice.start_date).format("L")} > ${dayjs(invoice.end_date).format("L")}`
    }, {
      title: t('common:text.invoices_address'),
      key: "address",
      computeValue: (invoice) => invoice.cups.full_address
    }, {
      title: t('common:text.invoices_import'),
      key: "import",
      computeValue: (invoice) => `${invoice.amount_total.toLocaleString(currentLang.value)} €`
    }, {
      title: t('common:text.invoices_pending'),
      key: "pending",
      computeValue: (invoice) => !invoice.paid && `${invoice.amount_pending.toLocaleString(currentLang.value)} €`
    }, {
      title: t('common:text.invoices_energy'),
      key: "energy",
      computeValue: (invoice) => `${invoice.energy_consumed.toLocaleString(currentLang.value)} kWh`
    }, {
      title: t('common:text.invoices_actions'),
      key: "actions",
      computeValue: (invoice) => <DownloadButton 
          invoiceId={invoice.id} 
          type={type} 
        />
    }
  ]

  const columnsMultipoint = [
    {
      title: t('common:text.invoices_invoice_number'),
      key: "num",
      computeValue: (invoice) => invoice.number
    }, {
      title: t('common:text.invoices_date'),
      key: "date",
      computeValue: (invoice) => dayjs(invoice.date).format("L")
    }, {
      title: t('common:text.invoices_address'),
      key: "address",
      computeValue: (invoice) => invoice.address_invoice_id	
    }, {
      title: t('common:text.invoices_import'),
      key: "import",
      computeValue: (invoice) => invoice.amount_total
    }, 
    // TODO: Implementar l'API per retornar aquestes dades
    // {
    //   title: t('common:text.invoices_pending'),
    //   key: "pending",
    //   computeValue: (invoice) => !invoice.paid && `${invoice.amount_pending.toLocaleString(currentLang.value)} €`
    // },
    {
      title: t('common:text.invoices_actions'),
      key: "actions",
      computeValue: (invoice) => <DownloadButton 
          invoiceId={invoice.id} 
          type={type} 
        />
    }
  ]

  const columns = type === 'electricity' ? columnsElectricity : (type === 'multipoint' ? columnsMultipoint : []);

  const getInvoiceStatus = (item) => {
      return null;
  }

  return {
    invoices, columns, getInvoiceStatus, loaded
  }
}

export default useInvoicesTable;
